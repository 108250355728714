import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyNPolicy() {
  return (
    <div className='termsWrapper'>
      <Link to='/' className='termsWrapper-name'>
        <span>MyReflection. ai</span>
      </Link>
      <div className='terms-container'>
        <div className='terms-content-container'>
          <h1 className='terms-title'>Privacy Policy</h1>
          <p className='terms-text'>
            CogniCore LLC ("CogniCore", "we", "us", and/or "our") operates the
            Myreflection mobile application (the "App"), the website
            Myrefletion.ai (the "Website"), and other related services
            (collectively, the "Services"). We are committed to protecting your
            privacy. This Privacy Policy describes how we collect, store, use,
            and share information through our Services.
          </p>
          <h2 className='terms-index'>1. Information We Collect</h2>
          <p className='terms-text'>
            <ul>
              <li>
                Through your use of the Services, you may provide us with the
                following information: Account information: This includes your
                name, email address, and password when you create an account.
                Profile information: We may ask you to provide additional
                information such as your birth date, gender, and interests.
                Messages and content: This includes the messages you send and
                receive through the App, such as memories, text, photos, videos,
                and voice messages you provide. Preferences: You may select
                conversation and communication preferences within the App.
                Payments and transactions: If you make purchases through the
                Services, our third-party payment processor will collect your
                payment information. We maintain a record of your purchases and
                the features you select. In-app purchases: If you make in-app
                purchases, our payment processing partner will collect necessary
                information to process your payment, such as your credit card
                number and billing address."
              </li>
              <li>
                B. Information We Collect Automatically We automatically log the
                following information about you, your device, and your
                interactions with our Services: Device information: This
                includes your device's operating system, manufacturer, model, IP
                address, unique device identifiers, language settings, and
                general location information such as city, state, or geographic
                area. Usage data: This includes information about how you use
                the Services, such as your interactions with the App, the
                features you use, and the pages you visit on the Website.
                Cookies and similar technologies: We use cookies, web beacons,
                and local storage technologies to collect some of this
                information. For more information, please see the "Cookies and
                Similar Technologies" section below.
              </li>
            </ul>
          </p>
          <h2 className='terms-index'>2. How We Use Your Information</h2>
          <p className='terms-text'>
            We use your information for the following purposes: To provide,
            maintain, and improve our Services, including to operate certain
            features and functionality of the Services. To understand and
            analyze how you use the Services and develop new products, services,
            features, and functionality. To communicate with you, provide you
            with updates and other information relating to the Services, provide
            information that you request, respond to comments and questions, and
            otherwise provide customer support. To facilitate the connection of
            third-party services or applications, such as social networks. For
            marketing and advertising purposes, such as developing and providing
            promotional and advertising materials that may be relevant, valuable
            or otherwise of interest to you. To generate anonymized, aggregate
            data containing only de-identified, non-personal information that we
            may use to publish reports. To find and prevent fraud, and respond
            to trust and safety issues that may arise. For compliance purposes,
            including enforcing our Terms of Service or other legal rights, or
            as may be required by applicable laws and regulations or requested
            by any judicial process or governmental agency. For other purposes
            for which we provide specific notice at the time the information is
            collected.
          </p>
          <h2 className='terms-index'>3. How We Share Your Information</h2>
          <p className='terms-text'>
            We may share your information in the following circumstances: With
            service providers and contractors: We may share your information
            with third-party companies and individuals that provide services on
            our behalf or help us operate the Services (such as customer
            support, hosting, analytics, email delivery, marketing, and database
            management services). With third parties for advertising and
            marketing: We may share your information with third parties for
            advertising and marketing purposes, such as to deliver relevant
            advertisements about our Services on other websites and
            applications. In connection with a business transaction: If we are
            involved in a merger, acquisition, financing due diligence,
            reorganization, bankruptcy, receivership, sale of company assets, or
            transition of service to another provider, your information may be
            sold or transferred as part of such a transaction as permitted by
            law and/or contract. We cannot control how such entities may use or
            disclose such information. For legal compliance and protection: We
            may disclose your information to respond to subpoenas, court orders,
            legal process, law enforcement requests, legal claims, or government
            inquiries, and to protect and defend the rights, interests, safety,
            and security of CogniCore, our users, or the public. With your
            consent: We may share your information for any other purposes
            disclosed to you at the time we collect the information or pursuant
            to your consent.
          </p>
          <h2 className='terms-index'>4. Cookies and Similar Technologies</h2>
          <p className='terms-text'>
            We and our third-party partners may use cookies and similar
            technologies to collect information about your use of the Services.
            Cookies are small data files stored on your hard drive or in device
            memory that help us improve the Services and your experience, see
            which areas and features of the Services are popular, and count
            visits. We may also collect information using web beacons (also
            known as "tracking pixels"). Web beacons are electronic images that
            may be used in the Services or emails and help deliver cookies,
            count visits, understand usage, and campaign effectiveness and
            determine whether an email has been opened and acted upon.
          </p>

          <h2 className='terms-index'>5. 5. Data Retention</h2>
          <p className='terms-text'>
            We store the information we collect about you for as long as is
            necessary for the purpose(s) for which we originally collected it,
            or for other legitimate business purposes, including to meet our
            legal, regulatory, or other compliance obligations.
          </p>
          <h2 className='terms-index'>6. Security</h2>
          <p className='terms-text'>
            We use reasonable and appropriate security measures to protect your
            information from loss, misuse and unauthorized access, disclosure,
            alteration and destruction, taking into account the risks involved
            in processing and the nature of such data, and to comply with
            applicable laws and regulations. However, no system is 100% secure,
            and we cannot guarantee the security of your information.
          </p>

          <h2 className='terms-index'>7. Third-Party AI Services</h2>
          <p className='terms-text'>
            To provide responses to users, our App utilizes third-party AI
            services, such as OpenAI, Groq, Anthropic, and Perplexity, via their
            APIs. When you interact with our App, your messages and other
            content you provide may be sent to these third-party services for
            processing. Please note that we do not have control over how these
            third parties handle your data and cannot guarantee that they will
            not use your data for training or other purposes. We encourage you
            to review the privacy policies of these third-party services to
            understand their data practices: OpenAI:
            <ul>
              <li> OpenAI: [https://openai.com/policies/privacy-policy/]</li>
              <li>Groq: [https://wow.groq.com/privacy-policy/] </li>
              <li>Anthropic: [https://www.anthropic.com/legal/privacy]</li>
              <li>
                Perplexity: [https://www.perplexity.ai/hub/legal/privacy-policy]
              </li>
            </ul>
            By using our Services, you acknowledge and agree that your
            information may be transmitted to and processed by these third-party
            AI services, and that CogniCore is not responsible for their
            handling of your data. We will make commercially reasonable efforts
            to engage with these third parties to prevent them from training on
            your data, but we cannot provide any guarantees at this time.
          </p>
          <h2 className='terms-index'>8. Children's Privacy</h2>
          <p className='terms-text'>
            Our Services are not intended for use by minors and we do not
            knowingly collect personal information from minors. If we learn that
            we have collected personal information from a minor, we will take
            steps to delete such information from our files as soon as possible.
          </p>
          <h2 className='terms-index'>9. Changes to This Privacy Policy</h2>
          <p className='terms-text'>
            We may update this Privacy Policy from time to time. If we make
            material changes, we will notify you by email or by posting a notice
            on the Services prior to the effective date of the changes. We
            encourage you to review the Privacy Policy whenever you access the
            Services to stay informed about our information practices and the
            ways you can help protect your privacy.
          </p>

          <h2 className='terms-contact'>Contact Us</h2>
          <p className='terms-text'>
            If you have any questions about these Terms, please contact us at
            <Link
              style={{ color: '#0eea96', textDecoration: 'none' }}
            >{`   support@myreflection.ai`}</Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyNPolicy;
